<template>
  <div class="notice-list">
    <app-list :opt="opt" @get="onGet" ref='noticeList'></app-list>
  </div>
</template>
<script>
export default {
  props: ['noticeFlag'],
  data() {
    let _this = this;
    return {
      opt: {
        columns: [
           {
            label: "时间",
            key: "createdDate",
            width:'200'
          },
           {
            label: "内容",
            key: "content",
          },
        ],
      },
      userId:''
      
    };
  },
  watch: {
      noticeFlag: function (val) { 
        let _this=this;      
       console.log('noticeFlag',val);   // 接收父组件的值
       _this.$refs.noticeList.onRefresh();
      }
    },
  created() {
    this.userId=this.cache.getLS("userInfo")["userId"]
    console.log("notice-list created!!");
    
  },
   activated() {
     
     console.log("notice-list activated!!");
      // this.getApartmentList();
  },
  mounted(){
      console.log("notice-list mounted!!");
  },
  methods: {
    onGet(opt) {
      let dto = {
        pageNumber: opt.skip,
        pageSize: opt.limit,
        type: '16',
        // ...opt.searchForm
      };
      if(this.userId!='1'){
        dto.toUser=this.userId
      }
      this.post("user-service/message/queryMessage", dto, {
        isUseResponse: true
      }).then(res => {
        // for (let i = 0; i < res.data.data.length; i++) {
        //   let item = res.data.data[i];
        //   item.type = ["蓝牙网关", "W7","L2蓝牙公钥"][item.type-1];
        //   item.lockStateStr = ["未绑定", "已绑定"][item.bindLockState];
        // }
        opt.cb(res.data);
      });
    },
  },
  
};
</script>
<style lang="scss" scope>
.notice-list {
    height: calc(100% - 130px);
}
</style>
