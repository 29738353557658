<template>
  <div>
      <div class="taskhouse"></div>
      <ul class="tab-ul">
          <li :class="{'ative':currentTab=='taskCenter'}" @click="toggleTab('taskCenter')">任务中心</li>
          <li :class="{'ative':currentTab=='messageList'}" @click="toggleTab('messageList')">消息列表</li>
      </ul>
      <keep-alive>
    <stock :is="currentTab"></stock>
  </keep-alive>
  </div>
</template>
<script>
  import taskCenter from './taskCenter/list.vue';
  import messageList from './messageList/list.vue';
  export default {
        components: {
            taskCenter,
            messageList,
        },
        data() {
            return {
                currentTab: 'taskCenter',
            }
        },
        activated() {
            if(this.$route.query.currentTab == 0){
                this.currentTab = 'taskCenter'
            }else{
                this.currentTab = 'messageList'
            }
        },
        methods: {
            toggleTab: function (tab) {
                this.currentTab = tab; // tab 为当前触发标签页的组件名
            },
        },
  }
</script>
<style lang="scss" scoped>
  .taskhouse {
      font-size: 18px;
      color: #303133;
      margin-bottom: 16px;
      padding-top: 8px;
  }
  // tab样式
  .tab-ul {
      list-style: none;
      padding-left: 0;
      margin-top: 0;
      height: 42px;
      box-sizing: border-box;
      margin-bottom: 40px;
      width: 100%;
      border-bottom: 2px solid #e4e7ed;

      li {
          cursor: pointer;
          padding-left: 18px;
          padding-right: 18px;
          height: 42px;
          line-height: 42px;
          float: left;
          text-align: center;
          margin-right: 13px;
          font-size: 15px;
          background-color: #ffffff;
          color: #303133;
          box-sizing: border-box;
          border-bottom: 2px solid #e4e7ed;

          &.ative {
              // color: #ffffff;
              // background: #5e74f1;
              color: #30BAC1;
              border-bottom: 2px solid #30BAC1;
          }
      }

      &::after {
          content: "";
          display: block;
          clear: both;
      }
  }
</style>