<template>
  <div class="notice-list">
    <app-list :opt="opt" @get="onGet" ref='noticeList'></app-list>
  </div>
</template>
<script>
export default {
  props: ['noticeFlag'],
  data() {
    let _this = this;
    return {
      opt: {
        search: [
            {
                key: "date",
                label: "时间",
                type: 'data-range',
            },
            {
              key: "type",
              label: "任务类型",
              type: 'select',
              opt: {
                list: [
                  {
                    value: "42",
                    label: '用户注销审核'
                  },
                  {
                    value: "43",
                    label: '系统意见反馈'
                  },
                  {
                    value: "44",
                    label: '售货意见反馈'
                  },
                  {
                    value: "45",
                    label: '提现审核'
                  },
                  {
                    value: "46",
                    label: '审核商家'
                  },
                  {
                    value: "47",
                    label: '审核经销商'
                  },
                  {
                    value: "48",
                    label: '审核供应商'
                  },
                  {
                    value: "49",
                    label: '审核房东'
                  },
                ],
              }
            },
        ],
        columns: [
          {
            label: "序号",
            key: "number",
            width: 200,
            align: "center"
          },
          {
            label: "任务类型",
            key: "typeStr",
            width: 180,
            align: "center"
          },
          {
            label: "时间",
            key: "createdDate",
            width: 300,
            align: "center"
          },
          {
            label: "任务描述",
            key: "content",
            // align: "center"
          },
          {
            label: "操作",
            key: "action",
            type: "action-but",
            align: "center",
            opt: {
              list: [{
                label: "前往处理",
                on(row) {
                  _this.goDeal(row)
                }
              }]
            }
          },
        ],
        
      },
      userId:'',
      roleIds:[]
    };
  },
  watch: {
      noticeFlag: function (val) { 
        let _this=this;      
       console.log('noticeFlag',val);   // 接收父组件的值
       _this.$refs.noticeList.onRefresh();
      }
    },
  created() {
    this.userId=this.cache.getLS("userInfo")["userId"]
    console.log("notice-list created!!");
  },
   activated() {
     this.roleIds = this.cache.getLS("userInfo")["roleIds"]
     console.log("notice-list activated!!");
     if(this.roleIds){
        if(this.roleIds[0]=='1'){
          this.opt.search.forEach((item, index) => {
            if (item.key == 'type') {
              item.opt.list= [{
                      value: "42",
                      label: '用户注销审核'
                    },
                    {
                      value: "43",
                      label: '系统意见反馈'
                    },
                    {
                      value: "44",
                      label: '售货意见反馈'
                    },
                    {
                      value: "45",
                      label: '提现审核'
                    },
                    {
                      value: "46",
                      label: '审核商家'
                    },
                    {
                      value: "47",
                      label: '审核经销商'
                    },
                    {
                      value: "48",
                      label: '审核供应商'
                    },
                    {
                      value: "49",
                      label: '审核房东'
                    },
                    {
                      value: "50",
                      label: '导出任务'
                    }]
            }
          })
        }else if(this.roleIds[0]=='11'){
          this.opt.search.forEach((item, index) => {
            if (item.key == 'type') {
              item.opt.list= [
                    {
                      value: "44",
                      label: '售货意见反馈'
                    },
                    {
                      value: "45",
                      label: '提现审核'
                    },
                    {
                      value: "46",
                      label: '审核商家'
                    },
                    {
                      value: "47",
                      label: '审核经销商'
                    },
                    {
                      value: "48",
                      label: '审核供应商'
                    },
                    {
                      value: "50",
                      label: '导出任务'
                    }]
            }
          })
        }else{
          this.opt.search.forEach((item, index) => {
            if (item.key == 'type') {
              item.opt.list= [
                {
                  value: "50",
                  label: '导出任务'
                }
              ]
            }
          })
        }
     }else{
        this.opt.search.forEach((item, index) => {
          if (item.key == 'type') {
            item.opt.list= []
          }
        })
     }
      // this.getApartmentList();
  },
  mounted(){
      console.log("notice-list mounted!!");
  },
  methods: {
    onGet(opt) {
      let dto = {
        pageNumber: opt.skip,
        pageSize: opt.limit,
        type: opt.searchForm.type ? opt.searchForm.type : null,
        startDate: opt.searchForm['date'] ? this.format(opt.searchForm['date'][0]) : null,
        endDate: opt.searchForm['date'] ? moment(opt.searchForm['date'][1]).format("YYYY-MM-DD 23:59:59") : null,
      };
      this.post("user-service/message/queryTaskMessage", dto, {
        isUseResponse: true
      }).then(res => {
        let pageNum = opt.skip;
        let pageSize = opt.limit;
        let num = (pageNum - 1) * pageSize;
        res.data.data.forEach(item=>{
          if(item.type == '42') {
            item.typeStr = '用户注销审核'
          }else if(item.type == '43') {
            item.typeStr = '系统意见反馈'
          }else if(item.type == '44') {
            item.typeStr = '售货意见反馈'
          }else if(item.type == '45') {
            item.typeStr = '提现审核'
          }else if(item.type == '46') {
            item.typeStr = '审核商家'
          }else if(item.type == '47') {
            item.typeStr = '审核经销商'
          }else if(item.type == '48') {
            item.typeStr = '审核供应商'
          }else if(item.type == '49') {
            item.typeStr = '审核房东'
          }else if(item.type == '50') {
            item.typeStr = '导出任务'
          }else{
            item.typeStr = '其他任务'
          }
          num++;
          item.number = num;
        })
        opt.cb(res.data);
      });
    },
    goDeal(row) {
      let dto = {
          id:row.id
      }
      this.post("user-service/message/updateTaskMessage",dto,{
        isUseResponse: true,
      }).then(res => {
        if(row.type == '42') {
          this.$router.push('/main/userList/list');
        }else if(row.type == '43') {
          this.$router.push('/main/feedback/list');
        }else if(row.type == '44') {
          this.$router.push('/main/containerFeedback/list');
        }else if(row.type == '45') {
          this.$router.push('/main/merchantManage/witchdrawalRecords/list');
        }else if(row.type == '46') {
          this.$router.push('/main/merchantManage/merchant/tabList');
        }else if(row.type == '47') {
          this.$router.push('/main/merchantManage/dealer/tabList');
        }else if(row.type == '48') {
          this.$router.push('/main/merchantManage/supplier/tabList');
        }else if(row.type == '49') {
          this.$router.push('/main/landlord/list');
        }else if(row.type == '50') {
          this.$router.push('/main/exportCenter/list');
        }
      })
    }
  },
  
};
</script>
<style lang="scss" scoped>
  .notice-list {
      height: calc(100% - 130px);
  }
</style>
